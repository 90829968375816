<template>
	<div class="outer-wrapper filters"> 

		<div id="top-nav"> 
			<router-link :to="{ name: 'Flights Results' }">
				<img src="@/assets/close-icon.png" alt="close" class="close-icon"/>
			</router-link>
		</div>

		<div class="inner-wrapper main"> <!-- Inner wrapper handles global padding -->
			<!-- {{ searchFilters }} -->

			<div class="match-origin">
				Depart from and return to the same airport
				<Toggle class="toggle" v-model="searchFilters.matchOrigin" />
			</div>

			<router-link class="filter-option" :to="{ name: 'Flights Filters Price' }">
				<div class="aligner"></div>
				<img class="icon" width="19px" src="@/assets/filters/price.png" />
				<div class="main-text">
					<div class="title">Price</div>
					<div class="sub" v-if="searchFilters.priceRange.low && searchFilters.priceRange.high">
						{{ formatPrice(searchFilters.priceRange.low) }} - {{ formatPrice(searchFilters.priceRange.high) }}
					</div>
					<div class="sub" v-else>
						Any
					</div>
				</div>
				<img class="arrow-right" width="24px" src="@/assets/filters/arrow-right.png" />
			</router-link>

			<router-link class="filter-option" :to="{ name: 'Flights Filters Stops' }">
				<div class="aligner"></div>
				<img class="icon" width="19px" src="@/assets/filters/stops.png" />
				<div class="main-text">
					<div class="title">Stops</div>
					<div class="sub">
						<span v-for="(option, key, index) in filterOptions.stops" :key="option.stops" >
							<span :class="{ 'cross': !searchFilters.stops[key] }">
								<span class="opt" v-if="option.stops == 0">Direct</span>
								<span class="opt" v-else-if="option.stops == 1">{{ option.stops }} stop</span>
								<span class="opt" v-else>{{ option.stops }} stops</span>
								<span v-if="key != Object.keys(searchFilters.stops)[Object.keys(searchFilters.stops).length - 1]">, </span>
							</span>
						</span>
					</div>
				</div>
				<img class="arrow-right" width="24px" src="@/assets/filters/arrow-right.png" />
			</router-link>

			<router-link class="filter-option airlines" :to="{ name: 'Flights Filters Airlines' }">
				<div class="aligner"></div>
				<img class="icon" width="19px" src="@/assets/filters/airlines.png" />
				<div class="main-text">
					<div class="title">Airlines</div>
					<!-- <div class="sub" :let="activeAirlines = Object.entries(searchFilters.airlines)
								.filter(([key, value]) => value === true)
								.map(([key]) => key)">
								{{ filterOptions.airlines }}
						<span v-for="(key) in activeAirlines.slice(0, 3)" :key="key" >
							<span>
								<span class="opt" v-if="filterOptions.airlines[key]">{{ filterOptions.airlines[key].name }}</span>
								<span class="opt" v-else>{{ key }}</span>
								<span v-if="key != activeAirlines[activeAirlines.slice(0, 3).length - 1] && key != activeAirlines[activeAirlines.length - 1]">, </span>
							</span>
						</span>
						<span :let="remainingAirlinesCount = activeAirlines.length > 3 ? activeAirlines.length - 3 : 0">
							<span v-if="remainingAirlinesCount > 0"> &amp; {{ remainingAirlinesCount }} more</span>
						</span>
						<span v-if="activeAirlines.length == 0">None</span>
					</div> -->
					<div class="sub">
						<span v-for="(option, index) in filteredAirlines" :key="option.key" v-if="index < 3">
							<span class="opt">{{ option.name }}</span>
							<span v-if="index < 2 && index < filteredAirlines.length - 1">, </span>
						</span>
						<span v-if="filteredAirlines.length > 3"> &amp; {{ filteredAirlines.length - 3 }} more</span>
					</div>
				</div>
				<img class="arrow-right" width="24px" src="@/assets/filters/arrow-right.png" />
			</router-link>

			<router-link class="filter-option" :to="{ name: 'Flights Filters Duration' }">
				<div class="aligner"></div>
				<img class="icon" width="24px" src="@/assets/filters/duration.png" />
				<div class="main-text">
					<div class="title">Duration</div>
					<div class="sub" v-if="searchFilters.maxDuration">
						Less than {{ formatTimeRange(searchFilters.maxDuration) }}
					</div>
					<div class="sub" v-else>
						Any
					</div>
				</div>
				<img class="arrow-right" width="24px" src="@/assets/filters/arrow-right.png" />
			</router-link>

			<router-link class="filter-option" :to="{ name: 'Flights Filters Times' }">
				<div class="aligner"></div>
				<img class="icon" width="20px" src="@/assets/filters/times.png" />
				<div class="main-text">
					<div class="title">Times</div>
					<div class="sub">
						<span v-if="searchFilters.outboundDepartureRange && searchFilters.outboundDepartureRange.low != 0 || searchFilters.outboundDepartureRange.high != 86340">
							Outbound {{ formatSecondsAsTime(searchFilters.outboundDepartureRange.low) }} - {{ formatSecondsAsTime(searchFilters.outboundDepartureRange.high) }}
						</span>
						<span v-else>
							Outbound Anytime
						</span>
						<span v-if="searchValues.tripType == 'return'">
							<span v-if="searchFilters.returnDepartureRange && (searchFilters.returnDepartureRange.low != 0 || searchFilters.returnDepartureRange.high != 86340)">
								&amp; Return {{ formatSecondsAsTime(searchFilters.returnDepartureRange.low) }} - {{ formatSecondsAsTime(searchFilters.returnDepartureRange.high) }}
							</span>
							<span v-else>
								&amp; Return Anytime
							</span>
						</span>
					</div>
				</div>
				<img class="arrow-right" width="24px" src="@/assets/filters/arrow-right.png" />
			</router-link>
			
		</div>

		<div class="bottom inner-wrapper">
			<router-link :to="{ name: 'Flights Results' }">
				<button class="button">View Results</button>
			</router-link>
		</div>
			
	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import Toggle from '@/components/Toggle.vue';
import helpers from '@/helpers/helpers.js';
import router from '@/router';

export default {
    data () {
        return {
           
        }
	},
	components: {
		Toggle,
	},
   computed: {
		...mapState({
			searchFilters: state => state.flightsSearch.searchFilters,
			searchValues: state => state.flightsSearch.searchValues,
			searchResults: state => state.flightsSearch.searchResults,
		}),
		...mapGetters({
			filterOptions: 'flightsSearch/getFilterOptions',
		}),

		filteredAirlines() {
			const airlines = [];
			for (const key in this.filterOptions.airlines) {
				if (this.searchFilters.airlines[key]) {
					airlines.push({ key, name: this.filterOptions.airlines[key].name });
				}
			}
			return airlines;
		},
	},
    methods: {
		formatSecondsAsTime: (seconds) => helpers.formatSecondsAsTime(seconds),
		formatTimeRange: (timeStamp) => helpers.formatTimeRange(timeStamp),
		formatPrice: (price) => helpers.formatPrice("GBP", price, 1, 0),
	},
	created () {
		if(this.searchResults.length == 0) { // Probably a page refresh - redirect to results page (with query params)
			router.push({
				name: 'Flights Results',
			})
		}
	},
};
</script>


<style scoped>
.close-icon {
	right: initial;
	left: 23px;
}
.match-origin {
	font-size: 12px;
	margin-bottom: 20px;
}
.toggle {
	float: right;
}

.filter-option {
	display: block;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
	padding: 15px 22px;
	height: 80px;
	box-sizing: border-box;
	vertical-align: center;
	position: relative;
	margin-bottom: 14px;
	color: initial !important;
}
.filter-option:visited {
	color: initial;
}
.filter-option .aligner {
	height: 100%;
	vertical-align: middle;
	width: 1px;
	display: inline-block;
}
.filter-option .icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: 22px;
}
.filter-option .main-text {
	vertical-align: middle;
	display: inline-block;
	max-width: calc(100% - 55px);
}
.filter-option .arrow-right {
	display: inline-block;
	vertical-align: middle;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}
.filter-option.airlines .sub {
	text-transform: capitalize;
}

.cross .opt {
	text-decoration: line-through;
}


</style>